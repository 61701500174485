import { cloneObject } from "@kws3/ui/utils";
import { USER } from "./globalStore";
import { get } from "svelte/store";

var Access = function () {
  var self = this;
  var ROLE = {
    CONTEXT_ADMIN: "A",
    CONTEXT_USER: "U",
    CONTEXT_PURCHASE_MANAGER: "CA",
    CONTEXT_LINE_MANAGER: "CM",
    CONTEXT_CUSTOMER_USER: "CU",
  };

  var ALLOWED = [
    ROLE.CONTEXT_ADMIN,
    ROLE.CONTEXT_USER,
    ROLE.CONTEXT_PURCHASE_MANAGER,
    ROLE.CONTEXT_LINE_MANAGER,
    ROLE.CONTEXT_CUSTOMER_USER,
  ];

  const SPECIAL_ROLE_SHARED_ACCOUNT = "SHARED_ACCOUNTS";

  var groups = {
    Users: { icon: "user-circle-o", title: "Users" },
    Inventory: { icon: "cubes", title: "Inventory" },
    Customers: { icon: "users", title: "Customers" },
    Orders: { icon: "shopping-cart", title: "Orders" },
  };

  var sections = {
    Dashboard: {
      icon: "dashboard",
      url: "",
    },
    Orders: {
      icon: "shopping-cart",
      url: "orders",
      title: "Orders",
    },
    Customers: {
      icon: "users",
      url: "customers",
      title: "Customers",
      group: "Customers",
    },
    AddCustomer: {
      icon: "user-plus",
      url: "customers/add",
      title: "Add Customer",
      group: "Customers",
    },
    Products: {
      icon: "cubes",
      url: "products",
      title: "Products",
    },
    Templates: {
      icon: "files-o",
      url: "inventory/templates",
      title: "Templates",
      group: "Inventory",
    },
    AddTemplate: {
      icon: "plus",
      url: "inventory/templates/add",
      title: "Add Template",
      group: "Inventory",
    },
    Categories: {
      icon: "list",
      url: "inventory/categories",
      title: "Categories",
      group: "Inventory",
    },
    Manufacturers: {
      icon: "cogs",
      url: "inventory/manufacturers",
      title: "Manufacturers",
      group: "Inventory",
    },
    Variations: {
      icon: "table",
      url: "inventory/variations",
      title: "Variations",
      group: "Inventory",
    },
    Users: {
      icon: "users",
      url: "users",
      title: "Users",
      group: "Users",
    },
    AddUser: {
      icon: "user-plus",
      url: "users/add",
      title: "Add User",
      group: "Users",
    },
    Reports: { icon: "line-chart", url: "reports" },
    //Settings: { icon: "cogs", url: "settings" },
    Profile: { visible: false },
    Queries: { visible: false },
    Reportqueries: { visible: false },
    Cart: { visible: false },
    Inventory: { visible: false },
    Success: { visible: false },
  };

  var allowedSections = {};

  var notAllowedSections = {
    [ROLE.CONTEXT_ADMIN]: ["Products", "Cart"],
    [ROLE.CONTEXT_USER]: ["Users", "AddUser", "Products", "Cart"],
    [ROLE.CONTEXT_PURCHASE_MANAGER]: [
      "Categories",
      "Manufacturers",
      "Templates",
      "AddTemplate",
      "Customers",
      "AddCustomer",
      "AddProduct",
      "Reports",
      "AddUser",
      "Settings",
      "Variations",
      "Inventory",
    ],
    [ROLE.CONTEXT_LINE_MANAGER]: [
      "Categories",
      "Manufacturers",
      "Templates",
      "AddTemplate",
      "Customers",
      "AddCustomer",
      "AddProduct",
      "Reports",
      "AddUser",
      "Settings",
      "Variations",
      "Inventory",
    ],
    [ROLE.CONTEXT_CUSTOMER_USER]: [
      "Categories",
      "Manufacturers",
      "Templates",
      "AddTemplate",
      "Customers",
      "AddCustomer",
      "Users",
      "AddUser",
      "AddProduct",
      "Reports",
      "Settings",
      "Variations",
      "Inventory",
    ],
  };

  Object.values(ROLE).forEach((role) => {
    let _sections = cloneObject(sections);

    //remove group if no submenu needed
    let _single_menus = ["Users"];
    if (
      role === ROLE.CONTEXT_CUSTOMER_USER ||
      role === ROLE.CONTEXT_PURCHASE_MANAGER ||
      role === ROLE.CONTEXT_LINE_MANAGER
    ) {
      _single_menus.forEach((item) => {
        delete _sections[item]["group"];
      });
    }

    //remove products section for super users
    if (role === ROLE.CONTEXT_ADMIN || role === ROLE.CONTEXT_USER) {
      _sections["Products"]["visible"] = false;
    }
    Object.keys(_sections).forEach((section) => {
      if (notAllowedSections[role].indexOf(section) !== -1) {
        delete _sections[section];
      }
    });
    allowedSections[role] = _sections;
  });

  allowedSections[ROLE.CONTEXT_CUSTOMER_USER]["Orders"] = {
    icon: "shopping-cart",
    url: "orders/needs_approval",
  };
  allowedSections[ROLE.CONTEXT_PURCHASE_MANAGER]["Orders"] = {
    icon: "shopping-cart",
    url: "orders/needs_approval",
  };
  allowedSections[ROLE.CONTEXT_LINE_MANAGER]["Orders"] = {
    icon: "shopping-cart",
    url: "orders/needs_approval",
  };
  allowedSections[ROLE.CONTEXT_ADMIN]["Orders"] = {
    icon: "shopping-cart",
    url: "orders/confirmed",
  };
  allowedSections[ROLE.CONTEXT_USER]["Orders"] = {
    icon: "shopping-cart",
    url: "orders/confirmed",
  };

  //Shared accounts
  allowedSections[SPECIAL_ROLE_SHARED_ACCOUNT] = cloneObject(
    allowedSections[ROLE.CONTEXT_CUSTOMER_USER]
  );
  delete allowedSections[SPECIAL_ROLE_SHARED_ACCOUNT]["Orders"];

  self.isAllowed = function (role) {
    return ALLOWED.indexOf(role) !== -1;
  };

  self.sectionAllowed = function (section, user) {
    if (user.should_change_password) {
      return section === "Profile";
    }
    const role = user.shared_account ? SPECIAL_ROLE_SHARED_ACCOUNT : user.role;
    return (
      typeof allowedSections[role] != "undefined" &&
      typeof allowedSections[role][section] != "undefined"
    );
  };

  self.mainSections = function (role) {
    let user = get(USER);
    if (user.shared_account) {
      return allowedSections[SPECIAL_ROLE_SHARED_ACCOUNT];
    }
    return allowedSections[role] || {};
  };

  self.mainGroups = function () {
    return groups;
  };

  self.roles = ROLE;
};

export default new Access();
