<div id="mainApp">
  <div id="updateReady" class={$UPDATE_READY ? "is-update-ready" : ""}>
    There is an update available, please reload window to activate updated
    version. &nbsp;&nbsp;&nbsp;
    <button
      type="button"
      class="button is-small is-light {$UPDATE_READY ? 'is-pulsing' : ''} "
      onclick="window.location.reload();">
      <strong>Reload Now</strong>
    </button>
  </div>

  <div
    id="mainContent"
    class="{$UPDATE_READY ? 'is-update-ready' : ''} {$SHOW_SIDEBAR
      ? 'is-sidebar-showing'
      : ''} {$COLLAPSED_SIDEBAR ? 'is-sidebar-collapsed' : ''}">
    <div id="contentArea">
      {#if !$IS_LOADING}
        <RouteHandler on:updateLoggedIn on:updateOrderLines on:hideSidebar />
      {/if}
    </div>

    <MainNavigation on:logout />

    <div id="header" class="level is-mobile">
      <div class="level-left">
        <div class="level-item logo-area">
          <button
            type="button"
            class="button navbar-burger
          is-hidden-tablet is-hidden-desktop is-hidden-widescreen is-hidden-fullhd {$SHOW_SIDEBAR
              ? 'is-active'
              : ''}"
            on:click={() => SHOW_SIDEBAR.toggle()}>
            <span />
            <span />
            <span />
          </button>
          <div class="logo-holder">
            <img class="logo logo-large" src="images/logo.png" alt="Logo" />
            <img
              class="logo logo-small"
              src="images/logo_small.png"
              alt="Logo" />
          </div>
        </div>
        <div class="level-item">
          <button
            type="button"
            class="button is-medium header-transparent-button is-hidden-mobile"
            on:click={() => fire("toggleSidebarExpanded")}>
            <Icon
              icon={$COLLAPSED_SIDEBAR
                ? "angle-double-right"
                : "angle-double-left"} />
          </button>
        </div>
      </div>

      <div class="level-item" />
      <div class="level-right">
        {#if environment}
          <span class="tag is-warning is-light mr-6">{environment}</span>
        {/if}
        <Cart />
      </div>
    </div>

    <div id="modals">
      <div class="modal {$SHOW_DUPLICATE_LOGIN_MODAL ? 'is-active' : ''}">
        <div class="modal-background" />
        <div class="modal-content">
          <div class="box">
            <h2 class="subtitle is-4">
              Your session is no longer valid on this device.
            </h2>
            <p>
              You may have logged in on another device.<br />Or your session has
              just expired.
              <br />
              <br />
            </p>
            <p>
              <small>
                <em
                  >You may only log in from one device at a time.
                  <br /> If you log in on this device, you will be logged out of
                  the other device.</em>
              </small>
            </p>

            <hr class="is-small" />

            <div class="field is-grouped is-grouped-right">
              <div class="control">
                <button
                  type="button"
                  class="button is-info is-pulled-right"
                  on:click={() => fire("logout")}>Log back in</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<Login on:reloadCurrentView />

<script>
  import { createEventDispatcher } from "svelte";
  import {
    SHOW_SIDEBAR,
    COLLAPSED_SIDEBAR,
    UPDATE_READY,
    IS_LOADING,
    SHOW_DUPLICATE_LOGIN_MODAL,
  } from "../services/globalStore";
  import MainNavigation from "./helpers/MainNavigation.svelte";
  import RouteHandler from "./helpers/RouteHandler.svelte";
  import Login from "./helpers/Login.svelte";
  import Cart from "./views/Cart.svelte";
  import { Icon, applySettings } from "@kws3/ui";
  import { K_ENV, K_ENV_DEV, K_ENV_STAGE } from "../env";

  applySettings({
    defaultIconFamily: "fa",
    defaultChartColors: [
      "#284BED",
      "#ED6134",
      "#1DAFEC",
      "#EDB405",
      "#11EDB7",
      "#77ED11",
    ],
  });

  let environment = false;
  if (K_ENV === K_ENV_DEV) {
    environment = "Dev";
  }
  if (K_ENV === K_ENV_STAGE) {
    environment = "Test";
  }

  //Have to export this so that we can fire events on App
  //from other places such as state.js
  export const fire = createEventDispatcher();
</script>
