{#if can_create_order}
  <div class="control">
    <span class="tag is-rounded is-danger cart-count-badge">{$CART_COUNT}</span>
    <button
      type="button"
      on:click={() => Router.navigate("#cart")}
      class="button is-info is-inverted"
      data-cy="cart"
      disabled={!$CART_COUNT}
      data-tooltip="View Cart">
      <Icon size="small" icon="cart-plus" />
    </button>
  </div>
{/if}

<style>
  .cart-count-badge {
    position: absolute;
    z-index: 9;
    left: -10px;
    top: -5px;
  }
</style>

<script>
  import { ROLE, CART_COUNT } from "../../services/globalStore";
  import { Icon } from "@kws3/ui";
  import Router from "../../services/router";

  $: can_create_order = ["CU", "CM", "CA"].indexOf($ROLE) !== -1;
</script>
