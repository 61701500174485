<div
  id="login"
  class="hero is-fullheight {showing ? 'is-showing' : ''} {loading
    ? 'is-loading'
    : ''}"
  data-cy="login">
  <div class="hero-body">
    <div class="container is-fullmaxwidth is-fullwidth">
      <div class="columns is-vcentered">
        <div class="column is-half logo is-text-centered">
          <img alt="logo" src="images/logo_large.png" />
          <p>&nbsp;<span class="app-loader">Loading ...</span></p>
        </div>

        <div class="column is-half login-box">
          {#if has_error}
            <!--eslint-disable-next-line @ota-meshi/svelte/no-at-html-tags-->
            <Message color="danger">{@html error_message}</Message>
          {:else}
            <div class="form-holder {shaking ? 'is-shaking' : ''}">
              <form on:submit|preventDefault={login}>
                <div class="field">
                  <div class="control has-icons-left">
                    <input
                      class="input is-medium"
                      type="text"
                      name="username"
                      placeholder="Username"
                      bind:value={username}
                      data-cy="username" />
                    <Icon icon="user" class="is-left" />
                  </div>
                </div>
                <div class="field">
                  <div class="control has-icons-left">
                    <input
                      class="input is-medium"
                      type="password"
                      name="password"
                      placeholder="Password"
                      bind:value={password}
                      data-cy="password" />
                    <Icon icon="key" class="is-left" />
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <button
                      type="submit"
                      class="button is-medium is-fullwidth is-primary {validating
                        ? 'is-loading'
                        : ''}"
                      disabled={!isReady}
                      data-cy="login-button">
                      Log In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<script>
  import { createEventDispatcher } from "svelte";
  import {
    USER,
    KEY,
    ROLE,
    IS_LOGGED_IN,
    IS_LOADING,
  } from "../../services/globalStore.js";
  import State from "../../services/state";
  import { Icon, Message } from "@kws3/ui";
  import CartModel from "../../models/cart";
  import OrdersModelShared from "../../models/ordersShared";

  export let error_message = "",
    has_error = false;

  const fire = createEventDispatcher();

  let validating = false,
    shaking = false,
    username = "",
    password = "",
    showing,
    loading,
    isReady;

  $: {
    showing = !$IS_LOGGED_IN || $IS_LOADING;
    loading = $IS_LOADING;
    isReady = username !== "" && password !== "" && !validating;
  }

  let valid = () => {
      validating = false;
      username = "";
      password = "";
    },
    invalid = () => {
      validating = false;
      shaking = true;
      setTimeout(() => {
        shaking = false;
        password = "";
      }, 500);
    },
    validatelogin = () => {
      State.authenticate(username, password).then(
        function (r) {
          if (r.valid) {
            valid();

            $USER = r;
            $KEY = r.key;
            $ROLE = r.role;
            $IS_LOGGED_IN = true;

            //load initial cart count
            if (["CU", "CM", "CA"].includes($ROLE)) {
              loadCartCount();
            }

            fire("reloadCurrentView");
            return;
          }
          invalid();
        },
        function (r) {
          console.log(r);
          invalid();
        }
      );
    };

  function login() {
    if (!validating) {
      validating = true;
      validatelogin();
    }
  }

  function loadCartCount() {
    if ($USER.shared_account) {
      OrdersModelShared.setCartCount();
    } else {
      CartModel.setCount();
    }
  }
</script>
