import {
  SKELETON_GRID,
  SKELETON_TILE,
  SKELETON_VIEW,
  SKELETON_CRUD,
} from "../utils/skeletonHelpers";

var routes = ["profile", "settings"];

var routeMap = {};

for (var i = 0; i < routes.length; i++) {
  var route = routes[i];
  routeMap[route] = [
    {
      pattern: "/",
      view: route.charAt(0).toUpperCase() + route.slice(1),
      skeleton: SKELETON_VIEW,
    },
  ];
}

routeMap["dashboard"] = [{ pattern: "/", view: "Dashboard" }];

routeMap["users"] = [
  { pattern: "/", view: "Users", skeleton: SKELETON_TILE },
  {
    pattern: "/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "Users",
    type: "regex",
    skeleton: SKELETON_TILE,
  },
  {
    pattern: "/view/(\\d+)$",
    view: "ViewUser",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  { pattern: "/add", view: "AddUser", skeleton: SKELETON_VIEW },
];

routeMap["products"] = [
  { pattern: "/", view: "Products", skeleton: SKELETON_TILE },
  {
    pattern: "/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "Products",
    type: "regex",
    skeleton: SKELETON_TILE,
  },
  {
    pattern: "/view/(\\d+)$",
    view: "ViewProduct",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  {
    pattern: "/view/(\\d+)/(details|images)/?$",
    view: "ViewProduct",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  { pattern: "/add", view: "AddProduct", skeleton: SKELETON_VIEW },
];

routeMap["orders"] = [
  {
    pattern: "/approved",
    view: "OrdersApproved",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/approved/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "OrdersApproved",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/needs_approval",
    view: "OrdersNeedApproval",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/needs_approval/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "OrdersNeedApproval",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/confirmed",
    view: "OrdersConfirmed",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/confirmed/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "OrdersConfirmed",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/processing",
    view: "OrdersProcessing",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/processing/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "OrdersProcessing",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/cancelled",
    view: "OrdersCancelled",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/cancelled/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "OrdersCancelled",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/completed",
    view: "OrdersComplete",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/completed/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "OrdersComplete",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/rejected",
    view: "OrdersRejected",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/rejected/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "OrdersRejected",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/view/(\\d+)$",
    view: "ViewOrder",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
];

routeMap["customers"] = [
  { pattern: "/", view: "Customers", skeleton: SKELETON_GRID },
  {
    pattern: "/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "Customers",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  { pattern: "/add", view: "AddCustomer", skeleton: SKELETON_VIEW },
  {
    pattern: "/view/(\\d+)$",
    view: "ViewCustomer",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  {
    pattern: "/view/(\\d+)/(details)/?$",
    view: "ViewCustomer",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  {
    pattern: "/view/(\\d+)/users/?$",
    view: "CustomerUsers",
    type: "regex",
    skeleton: SKELETON_TILE,
  },
  {
    pattern: "/view/(\\d+)/users/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "CustomerUsers",
    type: "regex",
    skeleton: SKELETON_TILE,
  },
  {
    pattern: "/view/(\\d+)/products/?$",
    view: "CustomerProducts",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/view/(\\d+)/products/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "CustomerProducts",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/view/(\\d+)/products/add",
    view: "AddCustomerProduct",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  {
    pattern: "/view/(\\d+)/products/view/(\\d+)$",
    view: "ViewCustomerProduct",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  {
    pattern: "/view/(\\d+)/users/view/(\\d+)$",
    view: "ViewCustomerUser",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  {
    pattern: "/view/(\\d+)/users/add",
    view: "AddCustomerUser",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  {
    pattern: "/view/(\\d+)/orders/?$",
    view: "CustomerOrders",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/view/(\\d+)/orders/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "CustomerOrders",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
];

routeMap["reports"] = [
  { pattern: "/", view: "Reports", skeleton: SKELETON_TILE },
  {
    pattern: "/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "Reports",
    type: "regex",
    skeleton: SKELETON_TILE,
  },
];

routeMap["reportqueries"] = [
  {
    pattern: "/view/(\\d+)$",
    view: "ViewReportQuery",
    type: "regex",
    childOf: "reports",
  },
];

routeMap["queries"] = [
  { pattern: "/", view: "Queries" },
  { pattern: "/(\\d+)/?([^/]*)?/?([^/]*)?/?$", view: "Queries", type: "regex" },
  { pattern: "/view/(\\d+)$", view: "ViewQuery", type: "regex" },
];

routeMap["cart"] = [
  { pattern: "/", view: "ViewCart", skeleton: SKELETON_VIEW },
];
routeMap["success"] = [{ pattern: "/", view: "OrderSuccess" }];

routeMap["inventory"] = [
  { pattern: "/categories", view: "Categories", skeleton: SKELETON_CRUD },
  { pattern: "/manufacturers", view: "Manufacturers", skeleton: SKELETON_CRUD },
  { pattern: "/templates", view: "Templates", skeleton: SKELETON_GRID },
  {
    pattern: "/templates/(\\d+)/?([^/]*)?/?([^/]*)?/?$",
    view: "Templates",
    type: "regex",
    skeleton: SKELETON_GRID,
  },
  {
    pattern: "/templates/add",
    view: "AddTemplate",
    skeleton: SKELETON_VIEW,
  },
  {
    pattern: "/templates/view/(\\d+)$",
    view: "ViewTemplate",
    type: "regex",
    skeleton: SKELETON_VIEW,
  },
  { pattern: "/variations", view: "Variations", skeleton: SKELETON_CRUD },
];
export default routeMap;
