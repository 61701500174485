import { writable } from "svelte/store";

/**
 *
 * @param {any} initial
 * @returns store with a custom method called toggle()
 * It's basically a writable store that has a custom method called toggle() which toggles the current value
 */
const toggleable = (initial) => {
  const store = writable(initial);

  return {
    ...store,
    toggle: () => store.update((n) => !n),
    turnOn: () => store.set(true),
    turnOff: () => store.set(false),
  };
};

export const IS_LOADING = toggleable(true);
export const IS_LOGGED_IN = toggleable(false);
export const USER = writable({ name: "", surname: "", email: "", avatar: "" });
export const ROLE = writable("A");
export const KEY = writable(null);
export const MAINSECTIONS = writable({});
export const MAINGROUPS = writable({});
export const ONLINE = toggleable(false);
export const NAV = writable({
  activeTab: "Home",
  activeView: "dashboard",
  params: {},
});
export const UPDATE_READY = toggleable(false);
export const SETTINGS = writable({ transition: true });
export const ICON_STYLE = writable("fa");
export const CART_COUNT = writable(0);
export const ORDER_LINES = writable({});

export const SHOW_DUPLICATE_LOGIN_MODAL = toggleable(false);
export const SHOW_SIDEBAR = toggleable(false);
export const COLLAPSED_SIDEBAR = toggleable(false);
