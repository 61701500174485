{#if is_loading}
  <Loader background_size="large" />
{/if}

{#if !is_loading}
  {#if categories.length}
    <div class="columns is-multiline dashboard-categories">
      {#each categories as c}
        <div class="column is-3">
          <div class="box p-2">
            <a href="#products/0/~category:{c.id}/">
              <figure>
                <img src={c.product_image} alt="Product Thumbnail" />
                <figcaption>
                  <span class="tag is-light is-size-5">{c.name}</span>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      {/each}
    </div>
  {/if}
{/if}

<script>
  import { Loader } from "@kws3/ui";
  import { onMount } from "svelte";
  export let model = null;

  let is_loading = false,
    is_error = false, // eslint-disable-line no-unused-vars
    error_message = "", // eslint-disable-line no-unused-vars
    categories = [];

  onMount(() => {
    loadCategories();
  });

  function loadCategories() {
    is_loading = true;
    is_error = false;
    error_message = "";
    model
      .getDashboardCategories()
      .then((r) => {
        is_loading = false;
        categories = r.response;
      })
      .catch((r) => {
        is_loading = false;
        is_error = true;
        error_message = r.response.records.userMessage || "";
        setTimeout(() => {
          is_error = false;
          error_message = "";
        }, 500);
      });
  }
</script>
