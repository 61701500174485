import baseModel from "./basemodel.js";
import { CART_COUNT } from "../services/globalStore";

export default new (class extends baseModel {
  constructor() {
    super();
    this.apiEndpoint = "/cart";
  }

  checkout(payload) {
    return this.create(payload, this.apiEndpoint + "/checkout");
  }

  //Internal Functions
  setCount(data) {
    let count = 0;
    if (!data) {
      this.getAll().then((r) => this.setCount(r.response));
    } else {
      if (data.order_lines) {
        let order_lines = data.order_lines;
        for (let i = 0; i < order_lines.length; i++) {
          count += Number(order_lines[i].quantity);
        }
      }
    }
    CART_COUNT.set(count);
  }
})();
