<div id="mainNav" class="has-custom-scrollbar" data-cy="mainNav">
  <div class="tabs" style="overflow:visible">
    <ul>
      <li>
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <div
              class="media"
              on:click|preventDefault={() => (show_submenu = !show_submenu)}>
              <div class="media-left">
                {#if $USER.avatar}
                  <figure class="image is-64x64">
                    <img src={$USER.avatar} alt="Avatar" />
                  </figure>
                {/if}
              </div>
              <div class="media-content" style="overflow: hidden">
                <span
                  class="is-block user-full-name"
                  style="line-height:1.3rem"
                  data-cy="userNameHeader"
                  >{$USER.name} {$USER.surname || ""}</span>
                <small class="is-block" data-cy="roleMarker"
                  >{userRoleName}
                  {#if $USER.shared_account}
                    <span
                      class="tag is-small is-info"
                      style="margin-top: 5px;line-height: 1.5rem;">
                      Shared
                    </span>
                  {/if}</small>
              </div>
              <div class="media-right">
                <Icon
                  icon={!$COLLAPSED_SIDEBAR && !show_submenu
                    ? "angle-right"
                    : "angle-down"}
                  class="dd-icon"
                  inner_class="fa-2x"
                  size="small" />
              </div>
            </div>

            <div
              class="dropdown-menu profile-items {show_submenu
                ? ''
                : 'collapsed'}">
              <div class="dropdown-content">
                <!--<a href="#settings/" class="dropdown-item">
                  <Icon icon="cog" size="small" />
                  <span>Settings</span>
                </a>-->
                <a href="#profile/" class="dropdown-item" data-cy="profile">
                  <Icon icon="user" size="small" />
                  <span>My Profile</span>
                </a>

                <hr class="dropdown-divider" />

                <a
                  href="#/"
                  class="dropdown-item"
                  on:click|preventDefault={() => fire("logout")}
                  data-cy="logout">
                  <Icon icon="sign-out" size="small" />
                  <span>Log out</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>

      {#each sections as section}
        <MainNavigationItem {section} />
      {/each}
    </ul>
  </div>
</div>

<script>
  import { createEventDispatcher } from "svelte";
  import {
    MAINSECTIONS,
    MAINGROUPS,
    USER,
    ROLE,
    COLLAPSED_SIDEBAR,
  } from "../../services/globalStore.js";
  import { Icon } from "@kws3/ui";
  import MainNavigationItem from "./MainNavigationItem.svelte";

  const fire = createEventDispatcher();

  let sections;
  let show_submenu = false;
  let userRoleName = "",
    roles = {
      A: "Super Admin",
      U: "Super User",
      CA: "Purchase Manager",
      CM: "Line Manager",
      CU: "User",
    };

  $: {
    if ($ROLE) {
      userRoleName = roles[$ROLE];
    }
  }

  $: {
    let ret = [];
    for (let k in $MAINSECTIONS) {
      // eslint-disable-next-line no-prototype-builtins
      if ($MAINSECTIONS.hasOwnProperty(k)) {
        let element = $MAINSECTIONS[k],
          item = {
            name: k,
            title: element.title ? element.title : k,
            url: element.url,
            icon: element.icon,
            visible: element.visible,
            children: [],
          };

        if (element.visible !== false) {
          // eslint-disable-next-line no-prototype-builtins
          if (element.hasOwnProperty("group")) {
            if (typeof ret[element.group] == "undefined") {
              ret[element.group] = {
                name: element.group,
                title: element.group,
                icon: element.icon,
                children: [],
              };
              let groupDetails = $MAINGROUPS[element.group];
              if (groupDetails) {
                ret[element.group].name = groupDetails.name || element.group;
                ret[element.group].title = groupDetails.title || element.group;
                ret[element.group].icon =
                  groupDetails.icon || ret[element.group].icon;
              }
            }
            ret[element.group].children.push(item);
          } else {
            if (typeof ret[k] == "undefined") {
              ret[k] = item;
            } else {
              let ch = ret[k].children;
              ret[k] = item;
              ret[k].children = ch;
            }
          }
        }
      }
    }

    sections = Object.values(ret);
  }
</script>
