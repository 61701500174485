import { CART_COUNT, ORDER_LINES, USER } from "../services/globalStore";
import { get } from "svelte/store";

export default new (class {
  constructor() {
    this.statuses = {
      N: {
        name: "Awaiting Approval",
        color: "warning",
        message: "Waiting for customer admin to approve the order",
      },
      R: {
        name: "Rejected",
        color: "danger",
        message: "Order rejected by customer admin",
      },
      C: {
        name: "Confirmed",
        color: "success",
        message: "Order confimed by super admin",
      },
      X: {
        name: "Cancelled",
        color: "danger",
        message: "Order cancelled by super admin",
      },
      I: {
        name: "Incomplete",
        color: "danger",
        message: "Incomplete Order",
      },
      NC: {
        name: "Awaiting Confirmation",
        color: "success",
        message: "Order approved by line manager",
      },
      LR: {
        name: "Line Manager Rejected",
        color: "danger",
        message: "Order rejected by line manager",
      },
      P: {
        name: "Processing",
        color: "success",
        message: "Order is being processed",
      },
      S: {
        name: "Completed",
        color: "success",
        message: "Order has been delivered",
      },
    };

    this.status_url_arr = {
      N: "needs_approval",
      R: "rejected",
      LR: "rejected",
      C: "confirmed",
      X: "cancelled",
      P: "processing",
      S: "completed",
      A: "approved",
      NC: "needs_approval",
    };
  }

  createOrder(payload) {
    return new Promise((fulfil) => {
      let { item, quantity } = payload;
      item["variations"] = this.toLowerKeys(item["variations"]);

      let sizeKey = "size" in item["variations"] ? "size" : "sizes";
      let sizeVariations = item["variations"][sizeKey] || null;

      let single_size_option = false,
        linked_variations = item.linked_variations || [];
      if (linked_variations && linked_variations.length) {
        linked_variations.forEach((element) => {
          let key = element.name;
          if (
            key.trim().toLowerCase() === "size" ||
            key.trim().toLowerCase() === "sizes"
          ) {
            if (element.variation_options.length === 1) {
              single_size_option = true;
            }
          }
        });
      }

      if (sizeVariations !== null && !single_size_option) {
        Object.entries(sizeVariations).forEach(([sizeName, sizeProps]) => {
          let _item = { ...item };
          let _variations = { ..._item.variations };

          _variations[sizeKey] = {
            0: sizeName,
            1: sizeProps[1],
            2: sizeProps[2],
            3: sizeProps[3],
          };

          _item["variations"] = { ..._variations };
          _item["quantity"] = sizeProps[3];

          this.makeOrderLines(_item, sizeProps[3]);

          item["id"] += 1; //increment order line id
        });
      } else {
        this.makeOrderLines(item, quantity);
      }

      fulfil();
    });
  }

  makeOrderLines(item, quantity) {
    let order_lines = get(ORDER_LINES) || {},
      user = get(USER);

    let user_lines = order_lines[`${user.id}`] || [];

    //compare variations
    let index = 0;
    let existing_line = false;

    if (user_lines.length) {
      for (index; index < user_lines.length; index++) {
        let existingItem = user_lines[index];
        if (existingItem.product === item.product) {
          existing_line = true;

          if (Object.keys(item.variations).length) {
            existing_line = this.compareVariations(existingItem, item);
          }

          if (existing_line) break;
        }
      }
    }

    if (existing_line) {
      user_lines[index].quantity += quantity;
    } else {
      user_lines.push(item);
    }

    order_lines[`${user.id}`] = user_lines;

    ORDER_LINES.set(order_lines);

    this.setCartCount();
  }

  toLowerKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  compareVariations(existingItem, newItem) {
    let existingVariations = existingItem.variations;
    let newVariations = newItem.variations;

    //both have variations
    if (
      !Object.keys(existingVariations).length ||
      !Object.keys(newVariations).length
    ) {
      return false;
    }

    //both has same variations count
    if (
      Object.keys(existingVariations).length !==
      Object.keys(newVariations).length
    ) {
      return false;
    }

    //compare variations
    let is_same = true;
    for (let key in newVariations) {
      if (
        typeof existingVariations[key] == "undefined" ||
        newVariations[key][0] !== existingVariations[key][0]
      ) {
        is_same = false;
        break;
      }
    }

    return is_same;
  }

  updateOrderData(order_lines, index, quantity) {
    order_lines[index].quantity += quantity;
    ORDER_LINES.set(order_lines);
    return order_lines;
  }

  updateOrderLine(payload) {
    return this.create(payload);
  }

  //Internal Functions
  setCartCount() {
    let order_lines = get(ORDER_LINES) || {},
      user = get(USER),
      user_lines = order_lines[`${user.id}`] || [],
      count = 0;

    if (user_lines && user_lines.length) {
      for (let i = 0; i < user_lines.length; i++) {
        count += Number(user_lines[i].quantity);
      }
    }

    CART_COUNT.set(count);
  }
})();
