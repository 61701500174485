<div class="page-content">
  <div class="inner-content">
    <Transition type="fly" y="{20}">
      <Message type="danger" size="large">
        <h1 class="has-text-centered title has-text-danger">
          <Icon size="large" icon="chain-broken"/>
          <br>
          404 Error
        </h1>
        <h4 class="has-text-centered subtitle has-text-danger">The page you requested could not be found.</h4>
      </Message>
    </Transition>
  </div>
</div>

<script>
  import { Message, Icon, Transition } from '@kws3/ui';
  import Router from '../../services/router';
  import { USER } from '../../services/globalStore';

  let is_redirect = false;

  $: is_redirect = $USER && $USER.should_change_password ? true : false;
  $: $USER, redirectToProfile();

  function redirectToProfile(){
    if(is_redirect){
      Router.navigate("profile");
    }
  }

</script>
