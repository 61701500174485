<div class="page-content">
  <div class="columns">
    <div class="column">
      <PeriodicChart />
    </div>
  </div>

  <div class="columns">
    <div class="column">
      <a class="box" href="#profile">
        <div class="media">
          <div class="media-left">
            {#if $USER.avatar}
              <figure class="image is-48x48">
                <img src={$USER.avatar} alt="Avatar" />
              </figure>
            {/if}
          </div>
          <div class="media-content">
            <div class="title is-3">
              {$USER.name}
              {$USER.surname || ""}
            </div>
            <div class="subtitle has-text-grey is-size-6">
              {userRoleName}
            </div>
          </div>
          <div class="media-right">
            <div class="field is-grouped is-align-items-center">
              <div class="control is-expanded has-text-right">
                <div class="title is-4">View Profile</div>
                <div class="subtitle has-text-grey is-size-6">
                  Update your profile picture, username etc
                </div>
              </div>
              <div class="control">
                <Icon icon="angle-right" size="large" />
              </div>
            </div>
          </div>
        </div>
      </a>

      <div class="columns is-multiline">
        {#if sections && sections.length}
          {#each sections as section}
            <div class="column is-4">
              <a class="box" href="#{section.url}">
                <Icon icon={section.icon} size="" />
                <span class="is-size-5"
                  >&nbsp;{section.title || section.name}</span>
              </a>
            </div>
          {/each}
        {/if}
      </div>
    </div>
  </div>
</div>

<script>
  import { Icon } from "@kws3/ui";
  import { MAINSECTIONS, USER, ROLE } from "../../services/globalStore";
  import PeriodicChart from "./helpers/dashboard/PeriodicChart.svelte";

  let sections = [];

  let userRoleName = "",
    roles = {
      A: "Super Admin",
      U: "Super User",
      CA: "Purchase Manager",
      CM: "Line Manager",
      CU: "User",
    };

  $: {
    if ($ROLE) {
      userRoleName = roles[$ROLE];
    }
  }

  $: {
    for (let k in $MAINSECTIONS) {
      let element = $MAINSECTIONS[k];
      let hiddenSections = ["Dashboard"];
      if (element.visible !== false && hiddenSections.indexOf(k) === -1) {
        sections.push({ name: k, ...element });
        // eslint-disable-next-line no-self-assign
        sections = sections;
      }
    }
  }
</script>
