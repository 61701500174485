<div class="page-content">
  <div class="columns">
    <div class="column">
      <a class="box" href="#profile">
        <div class="field is-grouped is-align-items-center">
          <div class="control ">
            {#if $USER.avatar}
              <figure class="image is-48x48">
                <img src={$USER.avatar} alt="Avatar" />
              </figure>
            {/if}
          </div>
          <div class="control is-expanded is-hidden-mobile">
            <div class="title is-4">
              {$USER.name}
              {$USER.surname || ""}
            </div>
            <div class="subtitle has-text-grey is-size-6">
              {userRoleName}
            </div>
          </div>
          <div class="control is-expanded">
            <div class="field is-grouped is-align-items-center">
              <div class="control is-expanded has-text-right">
                <div
                  class="is-hidden-tablet is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
                  <div class="title is-4 is-marginless ">
                    {$USER.name}
                    {$USER.surname || ""}
                  </div>
                  <div class="title is-5 is-marginless">View Profile</div>
                </div>
                <div class="is-hidden-mobile">
                  <div class="title is-4">View Profile</div>
                  <div
                    class="subtitle has-text-grey is-size-6 is-hidden-mobile">
                    Update your profile picture, username etc
                  </div>
                </div>
              </div>
              <div class="control">
                <Icon icon="angle-right" size="large" />
              </div>
            </div>
          </div>
        </div>
      </a>

      <div class="columns is-multiline">
        {#if sections && sections.length}
          {#each sections as section}
            <div class="column is-4">
              <a class="box" href="#{section.url}">
                <Icon icon={section.icon} size="" />
                <span class="is-size-5"
                  >&nbsp;{section.title || section.name}</span>
              </a>
            </div>
          {/each}
        {/if}
      </div>
    </div>

    <div class="column is-4" style="position:relative;">
      {#if is_loading}
        <Loader spinner_color="primary" has_overlay={true} />
      {/if}

      {#if is_error}
        <Notification color="danger">{error_message}</Notification>
      {/if}

      <div class="columns is-multiline">
        <div class="column is-12">
          <a
            class="box"
            href="#orders/needs_approval/{is_purchase_manager
              ? '0/~status:N/'
              : ''}">
            <div class="media">
              <div class="media-content">
                <div class="title is-4">
                  Awaiting Approval
                  <span class="has-text-info">({aw_approval_count})</span>
                </div>
                <div class="subtitle has-text-grey is-size-6">
                  List of orders waiting for approval
                </div>
              </div>
            </div>
          </a>
        </div>

        {#if is_purchase_manager}
          <div class="column is-12">
            <a class="box" href="#orders/needs_approval/0/~status:NC/">
              <div class="media">
                <div class="media-content">
                  <div class="title is-4">
                    Awaiting Confirmation
                    <span class="has-text-info">
                      ({aw_confirmation_count})
                    </span>
                  </div>
                  <div class="subtitle has-text-grey is-size-6">
                    List of orders waiting for confirmation
                  </div>
                </div>
              </div>
            </a>
          </div>
        {/if}
      </div>
    </div>
  </div>

  <hr class="" style="background-color: rgb(215 215 215);height: 1px;" />

  <DashboardCategories model={categoryModel} />
</div>

<script>
  import { Icon, Notification, Loader } from "@kws3/ui";
  import { MAINSECTIONS, USER, ROLE } from "../../../services/globalStore";
  import OrderModel from "../../../models/orders";
  import categoryModel from "../../../models/categories";
  import { onMount } from "svelte";
  import DashboardCategories from "../helpers/DashboardCategories.svelte";

  let sections = [];
  let aw_approval_count = 0,
    aw_confirmation_count = 0;

  let is_loading = true,
    is_error = false,
    error_message = "";

  let userRoleName = "",
    roles = {
      A: "Super Admin",
      U: "Super User",
      CA: "Purchase Manager",
      CM: "Line Manager",
      CU: "User",
    };

  $: {
    if ($ROLE) {
      userRoleName = roles[$ROLE];
    }
  }

  $: user_role = $USER && $USER.role;
  $: is_purchase_manager = user_role === "CA";
  $: is_line_manager = user_role === "CM";

  $: {
    for (let k in $MAINSECTIONS) {
      let element = $MAINSECTIONS[k];
      let hiddenSections = ["Dashboard"];
      if (element.visible !== false && hiddenSections.indexOf(k) === -1) {
        sections.push({ name: k, ...element });
      }
    }
  }

  onMount(() => {
    if (is_purchase_manager) {
      loadCounts();
    }
    if (is_line_manager) {
      loadPendingCounts();
    }
  });

  function loadCounts() {
    is_loading = true;
    Promise.all([
      OrderModel.getCountbyStatus("N"),
      OrderModel.getCountbyStatus("NC"),
    ])
      .then((r) => {
        is_loading = false;
        aw_approval_count = r[0].response.count;
        aw_confirmation_count = r[1].response.count;
      })
      .catch((r) => {
        is_loading = false;
        is_error = true;
        error_message = r.response.records.userMessage || "";

        setTimeout(() => {
          is_error = false;
          error_message = "";
        }, 500);
      });
  }

  function loadPendingCounts() {
    is_loading = true;
    OrderModel.getCountbyStatus("N")
      .then((r) => {
        is_loading = false;
        aw_approval_count = r.response.count;
      })
      .catch((r) => {
        is_loading = false;
        is_error = true;
        error_message = r.response.records.userMessage || "";

        setTimeout(() => {
          is_error = false;
          error_message = "";
        }, 500);
      });
  }
</script>
