<div class="inner-content {classes}">
  <div style="position: relative">
    {#if is_loading}
      <slot name="skeleton">
        <Loader background_size="large" spinner_size="large" />
      </slot>
    {:else if is_error}
      <Message color="danger">
        There was an error while fetching data from the server.<br />
        {#if error_message}The server returned <code>{error_message}</code>{/if}
      </Message>
    {:else}
      <Transition
        transition={has_transition && !$$slots.skeleton}
        {...transition_options}>
        <div>
          <slot />
        </div>
      </Transition>
    {/if}
  </div>
</div>

<script>
  import { SETTINGS } from "../../services/globalStore";
  import { Message, Loader, Transition } from "@kws3/ui";

  let has_transition, transition;

  $: has_transition =
    typeof transition != "undefined" ? transition : $SETTINGS.transition;

  let classes = "";
  export { classes as class };

  export let is_loading = true,
    is_error = false,
    error_message = "";

  let transition_options = {
    type: "fly",
    x: 0,
    y: 20,
    delay: 0,
    duration: 300,
    easing: null,
  };
</script>
