<div class="app-content">
  <svelte:component
    this={views[$NAV.activeView]}
    on:updateLoggedIn
    on:updateOrderLines
    on:hideSidebar />
</div>

<script>
  import { onMount, createEventDispatcher } from "svelte";

  import { NAV } from "../../services/globalStore.js";
  import Routes from "../../config/routes.js";
  import Router from "../../services/router.js";

  import PageNotFound from "../views/PageNotFound.svelte";
  import PageAssetsLoading from "../views/PageAssetsLoading.svelte";

  import Dashboard from "../views/Dashboard.svelte";

  const lazyViews = {
    Users: () => import("../views/users/Users.svelte"),
    AddUser: () => import("../views/users/AddUser.svelte"),
    ViewUser: () => import("../views/users/ViewUser.svelte"),
    Settings: () => import("../views/Settings.svelte"),
    Profile: () => import("../views/Profile.svelte"),
    Reports: () => import("../views/Reports.svelte"),
    ViewReportQuery: () => import("../views/reports/ViewReportQuery.svelte"),
    Queries: () => import("../views/queries/Queries.svelte"),
    ViewQuery: () => import("../views/queries/ViewQuery.svelte"),
    Products: () => import("../views/products/Products.svelte"),
    AddProduct: () => import("../views/products/AddProduct.svelte"),
    ViewProduct: () => import("../views/products/ViewProduct.svelte"),
    Templates: () => import("../views/products/Templates.svelte"),
    AddTemplate: () => import("../views/products/AddTemplate.svelte"),
    ViewTemplate: () => import("../views/products/ViewTemplate.svelte"),
    Categories: () => import("../views/products/Categories.svelte"),
    Manufacturers: () => import("../views/products/Manufacturers.svelte"),
    Customers: () => import("../views/customers/Customers.svelte"),
    AddCustomer: () => import("../views/customers/AddCustomer.svelte"),
    ViewCustomer: () => import("../views/customers/ViewCustomer.svelte"),
    CustomerUsers: () => import("../views/customers/CustomerUsers.svelte"),
    AddCustomerUser: () => import("../views/customers/AddCustomerUser.svelte"),
    ViewCustomerUser: () =>
      import("../views/customers/ViewCustomerUser.svelte"),
    Orders: () => import("../views/orders/Orders.svelte"),
    OrdersApproved: () => import("../views/orders/lists/OrdersApproved.svelte"),
    OrdersComplete: () => import("../views/orders/lists/OrdersComplete.svelte"),
    OrdersRejected: () => import("../views/orders/lists/OrdersRejected.svelte"),
    OrdersNeedApproval: () =>
      import("../views/orders/lists/OrdersNeedApproval.svelte"),

    OrdersConfirmed: () =>
      import("../views/orders/lists/OrdersConfirmed.svelte"),
    OrdersProcessing: () =>
      import("../views/orders/lists/OrdersProcessing.svelte"),
    OrdersCancelled: () =>
      import("../views/orders/lists/OrdersCancelled.svelte"),

    ViewOrder: () => import("../views/orders/ViewOrder.svelte"),
    CustomerOrders: () => import("../views/customers/CustomerOrders.svelte"),
    CustomerProducts: () =>
      import("../views/customers/CustomerProducts.svelte"),
    AddCustomerProduct: () =>
      import("../views/customers/AddCustomerProduct.svelte"),
    ViewCustomerProduct: () =>
      import("../views/customers/ViewCustomerProduct.svelte"),
    ViewCart: () => import("../views/ViewCart.svelte"),

    Variations: () => import("../views/products/Variations.svelte"),

    OrderSuccess: () => import("../views/OrderSuccess.svelte"),
  };

  const fire = createEventDispatcher();

  const views = {
    PageNotFound,
    Dashboard,
    PageAssetsLoading,
  };

  function loadQueuedView(view) {
    if (typeof lazyViews[view] != "undefined") {
      var viewPromise = lazyViews[view];
      viewPromise()
        .then((mod) => {
          views[view] = mod.default ? mod.default : mod;
          //set it for later navigations to samepage
          // this.set({views});

          //check if we are still on same page
          var nav = $NAV;
          if (nav.shouldBeView === view) {
            nav.activeView = view;
            //update the page, as we are still on the same page
            $NAV = nav;
          }
        })
        .catch((e) => {
          console.log(e, "Module for " + view + " not found");
        });
    }
  }

  function routeChange(r) {
    var view = r.view,
      nav = $NAV,
      shouldBeView = view,
      skeleton = r.skeleton;

    if (view === "404") {
      view = "PageNotFound";
    } else if (typeof views[view] == "undefined") {
      if (typeof lazyViews[view] == "undefined") {
        view = "PageNotFound";
      } else {
        loadQueuedView(view);
        view = "PageAssetsLoading";
      }
    }

    nav = {
      activeView: view,
      shouldBeView: shouldBeView,
      activeTab: r.section ? r.section : view,
      params: r.params,
      baseSkeleton: skeleton,
    };

    $NAV = nav;

    fire("routeChange", r);
    fire("hideSidebar");
  }

  onMount(() => {
    Router.init(Routes, "dashboard", routeChange);
  });
</script>
