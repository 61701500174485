<div class="columns">
  <div class="column">
    <h1 class="title"><Skeleton width="100px" /></h1>
    <!-- eslint-disable-next-line -->
    {#each { length: 3 } as _}
      <p>
        <Skeleton
          lines={randomIntegerFromInterval(1, 6)}
          width={randomPercent()} />
      </p>
      <br /><br />
    {/each}
  </div>
  <div class="column">
    <h1 class="title"><Skeleton width="150px" /></h1>
    <!-- eslint-disable-next-line -->
    {#each { length: 3 } as _}
      <p>
        <Skeleton
          lines={randomIntegerFromInterval(1, 6)}
          width={randomPercent()} />
      </p>
      <br /><br />
    {/each}
  </div>
</div>

<script>
  import { Skeleton } from "@kws3/ui";
  import {
    randomPercent,
    randomIntegerFromInterval,
  } from "../../../../utils/templateHelpers";
</script>
