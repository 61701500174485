import { K_ENV, K_ENV_STAGE, K_ENV_PROD } from "../env.js";

var url = "";

switch (K_ENV) {
  case K_ENV_PROD:
    url = "https://ordersystem-api.whitelodgegroup.co.uk/v1";
    break;
  case K_ENV_STAGE:
    url = "https://ordersystem-test-api.whitelodgegroup.co.uk/v1";
    break;
  default:
    //url = "http://192.168.1.72:8080/html/apparel/apparel-api/src/public/v1";
    url = "http://localhost/v1";
    break;
}

export default url;
