export const SKELETON_GRID = "grid",
  SKELETON_TILE = "tile",
  SKELETON_VIEW = "view",
  SKELETON_CRUD = "crud";

export default {
  SKELETON_GRID,
  SKELETON_TILE,
  SKELETON_VIEW,
  SKELETON_CRUD,
};
