import App from "./components/App.svelte";
import { applySettings, activateTooltips } from "@kws3/ui";
import {
  SHOW_SIDEBAR,
  COLLAPSED_SIDEBAR,
  SHOW_DUPLICATE_LOGIN_MODAL,
  USER,
  KEY,
  ROLE,
  IS_LOGGED_IN,
  CART_COUNT,
  ORDER_LINES,
  UPDATE_READY,
} from "./services/globalStore";
import State from "./services/state";
import Router from "./services/router";
import DB from "./services/db";

State.init();

let db_state = DB.get("state");
if (db_state.icon_style) {
  applySettings({ defaultIconFamily: db_state.icon_style });
}

var app = new App({
  target: document.getElementById("app"),
});

activateTooltips("#mainApp");

State.linkToApp(app);

app.$on("hideSidebar", () => SHOW_SIDEBAR.turnOff());

// //--END--Mobile only functionality

app.$on("toggleSidebarExpanded", () => COLLAPSED_SIDEBAR.toggle());

app.$on("duplicateLogin", () => SHOW_DUPLICATE_LOGIN_MODAL.turnOn());

app.$on("updateReady", () => UPDATE_READY.turnOn());

app.$on("updateLoggedIn", (event) => {
  let payload = event.detail;
  USER.set(payload);
});

app.$on("updateOrderLines", (event) => {
  let payload = event.detail;
  ORDER_LINES.set(payload);
});

app.$on("sessionRefresh", (event) => {
  let r = event.detail;
  USER.set(r);
  KEY.set(r.key);
  ROLE.set(r.role);
  IS_LOGGED_IN.turnOn();
});

app.$on("logout", () => {
  State.deauthenticate();

  IS_LOGGED_IN.turnOff();
  USER.set({
    name: "",
    surname: "",
    email: "",
    username: "",
    avatar: "",
    should_change_password: "",
    shared_account: "",
  });
  KEY.set(null);
  ROLE.set(null);
  SHOW_DUPLICATE_LOGIN_MODAL.turnOff();
  CART_COUNT.set(0);
});

app.$on("reloadCurrentView", () => {
  var oh = window.location.hash;
  Router.navigate("nf");
  setTimeout(() => {
    Router.navigate(oh.substring(1));
  }, 10);
});

export default app;
