import baseModel from "./basemodel.js";

export default new (class extends baseModel {
  constructor() {
    super();
    this.apiEndpoint = "/orders";
    this.newEndpoint = "";

    this.statuses = {
      N: {
        name: "Awaiting Approval",
        color: "warning",
        message: "Waiting for customer admin to approve the order",
      },
      R: {
        name: "Rejected",
        color: "danger",
        message: "Order rejected by customer admin",
      },
      C: {
        name: "Confirmed",
        color: "success",
        message: "Order confimed by super admin",
      },
      X: {
        name: "Cancelled",
        color: "danger",
        message: "Order cancelled by super admin",
      },
      I: {
        name: "Incomplete",
        color: "danger",
        message: "Incomplete Order",
      },
      NC: {
        name: "Awaiting Confirmation",
        color: "success",
        message: "Order approved by line manager",
      },
      LR: {
        name: "Line Manager Rejected",
        color: "danger",
        message: "Order rejected by line manager",
      },
      P: {
        name: "Processing",
        color: "success",
        message: "Order is being processed",
      },
      S: {
        name: "Completed",
        color: "success",
        message: "Order has been delivered",
      },
    };

    this.status_url_arr = {
      N: "needs_approval",
      R: "rejected",
      LR: "rejected",
      C: "confirmed",
      X: "cancelled",
      P: "processing",
      S: "completed",
      A: "approved",
      NC: "needs_approval",
    };

    var oldGetAll = this.getAll;

    this.getCustomerOrders = (q) => {
      return oldGetAll.apply(this, [q, this.newEndpoint]);
    };

    //For Customer Users
    this.getAwaitingApproval = (q) => {
      return oldGetAll.apply(this, [
        q,
        `${this.apiEndpoint}/awaiting_approval`,
      ]);
    };

    this.getApproved = (q) => {
      return oldGetAll.apply(this, [q, `${this.apiEndpoint}/approved`]);
    };

    this.getRejected = (q) => {
      return oldGetAll.apply(this, [q, `${this.apiEndpoint}/rejected`]);
    };

    this.getConfirmed = (q) => {
      return oldGetAll.apply(this, [q, `${this.apiEndpoint}/confirmed`]);
    };

    this.getCancelled = (q) => {
      return oldGetAll.apply(this, [q, `${this.apiEndpoint}/cancelled`]);
    };

    this.getProcessing = (q) => {
      return oldGetAll.apply(this, [q, `${this.apiEndpoint}/processing`]);
    };

    this.getCompleted = (q) => {
      return oldGetAll.apply(this, [q, `${this.apiEndpoint}/completed`]);
    };
  }

  bulkAction(payload) {
    return this.create(payload, this.apiEndpoint + "/bulk_update");
  }

  getCountbyStatus(status) {
    return this.getOne("count/" + status);
  }
})();
