<div class="page-content">
  <div class="page-title">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <div class="media">
            <div class="media-left">
              <Skeleton width="42px" height="36px" color="primary" />
            </div>
            <div class="media-content">
              <p class="title">
                <Skeleton width="150px" color="primary" />
              </p>
              <p class="subtitle">
                <Skeleton width="250px" color="primary" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ViewScaffold {is_loading}>
    <div slot="skeleton">
      {#if shouldShowSkeleton}<svelte:component this={skeletonToUse} />{/if}
    </div>
    <Message color="danger">
      <p>It seems like assets for this page could not be loaded.</p>
      <p><br />You can wait a few seconds extra and see if it works.</p>
      <p>
        If not, reload the admin panel, there may have been an update in the
        background.
      </p>
    </Message>
  </ViewScaffold>
</div>

<script context="module">
  import SkeletonTypes from "../../utils/skeletonHelpers.js";
  import GenericUnknownSkeleton from "./skeleton/generic/GenericUnknownSkeleton.svelte";

  const genericSkeletonMap = {
    __: GenericUnknownSkeleton, //using this if we have no idea what skeleton to use
  };
  //other generic skeletons
  // eslint-disable-next-line no-unused-vars
  Object.entries(SkeletonTypes).forEach(([key, value]) => {
    genericSkeletonMap[value] = GenericUnknownSkeleton;
  });

  const lazySkeletons = {};
  lazySkeletons[SkeletonTypes.SKELETON_GRID] = () =>
    import("./skeleton/generic/SkeletonGrid.svelte");
  lazySkeletons[SkeletonTypes.SKELETON_TILE] = () =>
    import("./skeleton/generic/SkeletonTile.svelte");
  lazySkeletons[SkeletonTypes.SKELETON_VIEW] = () =>
    import("./skeleton/generic/SkeletonView.svelte");
  lazySkeletons[SkeletonTypes.SKELETON_CRUD] = () =>
    import("./skeleton/generic/SkeletonCRUD.svelte");

  const loadSkeletons = () => {
    // eslint-disable-next-line no-unused-vars
    Object.entries(lazySkeletons).forEach(([key, value]) => {
      lazySkeletons[key]().then((m) => {
        genericSkeletonMap[key] = m.default ? m.default : m;
      });
    });
  };

  loadSkeletons();
</script>

<script>
  import { onMount, onDestroy } from "svelte";
  import ViewScaffold from "../helpers/ViewScaffold.svelte";
  import { NAV } from "../../services/globalStore";
  import { Message, Skeleton } from "@kws3/ui";

  const gracePeriod = 500;
  const tooLong = 15000;

  $: $NAV, applyBaseSkeleton();

  let tooLongTimeout,
    gracePeriodTimeout,
    shouldShowSkeleton = false,
    page = {
      title: "Loading Assets",
      subtitle: "Please wait...",
      icon: "dot-circle-o",
    },
    is_loading = true,
    skeletonToUse = genericSkeletonMap["__"];

  const applyBaseSkeleton = () => {
    let baseSkeleton = $NAV.baseSkeleton;
    if (
      baseSkeleton &&
      typeof genericSkeletonMap[baseSkeleton] !== "undefined"
    ) {
      skeletonToUse = genericSkeletonMap[baseSkeleton];
    } else {
      skeletonToUse = genericSkeletonMap["__"];
    }
  };

  onMount(() => {
    tooLongTimeout = setTimeout(() => {
      is_loading = false;
      page.title = "Error";
      page.subtitle = "Failed to load assets";
    }, tooLong);

    gracePeriodTimeout = setTimeout(() => {
      shouldShowSkeleton = true;
    }, gracePeriod);
  });

  onDestroy(() => {
    if (tooLongTimeout) {
      clearTimeout(tooLongTimeout);
    }
    if (gracePeriodTimeout) {
      clearTimeout(gracePeriodTimeout);
    }
  });
</script>
