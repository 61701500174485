import { get } from "svelte/store";
import {
  IS_LOADING,
  IS_LOGGED_IN,
  USER,
  ROLE,
  KEY,
  MAINSECTIONS,
  MAINGROUPS,
  ONLINE,
  NAV,
  UPDATE_READY,
  ICON_STYLE,
  COLLAPSED_SIDEBAR,
  CART_COUNT,
  ORDER_LINES,
} from "./globalStore";
import "promis";
import DB from "./db";
import Net from "./net";
import { debounce } from "@kws3/ui/utils";
import Access from "./access";

var currentState = function () {
  var self = this,
    initial_state = {
      is_logged_in: get(IS_LOGGED_IN),
      user: get(USER),
      key: get(KEY),
      role: get(ROLE),
      nav: get(NAV),
      collapsed_sidebar: get(COLLAPSED_SIDEBAR),
      cart_count: get(CART_COUNT),
    },
    initial_cart = get(ORDER_LINES),
    App = null;

  self.init = function () {
    _init();
  };

  self.linkToApp = function (app) {
    App = app;
  };

  function _init() {
    document.activeElement && document.activeElement.blur();

    //move footer inside sidebar
    //var footer = document.querySelector("#footer"),
    //sideBar = document.querySelector("#sidebar");
    //sideBar.appendChild(footer);

    //load the state from localstorage
    var state = DB.get("state");
    if (typeof state != "undefined" && state) {
      initial_state = state;
    } else {
      DB.set("state", initial_state);
    }

    var cart = DB.get("cart");
    if (typeof cart != "undefined" && cart) {
      initial_cart = cart;
    } else {
      DB.set("cart", initial_cart);
    }

    //set authentication key in network comms
    Net.setKey(initial_state.key);

    //respond to Net errors
    Net.bind("httpError", function (code) {
      if (code === 409) {
        App.fire("duplicateLogin");
      }
      if (code === 401) {
        App.fire("noAccess");
      }
    });

    Net.bind("sessionRefresh", function (r) {
      Net.setKey(r.key);
      App.fire("sessionRefresh", r);
    });

    window.addEventListener(
      "storage",
      debounce(function () {
        //this is fired when localstorage changes in another tab
        let DB_state = DB.get("state");
        let existingUser = get(USER),
          existingKey = get(KEY),
          existing_logged_in = get(IS_LOGGED_IN);

        if (existing_logged_in === false) {
          IS_LOGGED_IN.turnOff();
        }
        if (
          existingUser &&
          existingUser.id &&
          DB_state.user.id === existingUser.id &&
          DB_state.key.access_token !== existingKey.access_token
        ) {
          initial_state.key = DB_state.key;
          Net.setKey(DB_state.key);
          //eslint-disable-next-line no-undef
          key.set(DB_state.key);
        }
      })
    );

    //set app state
    initial_state.online = navigator.onLine;

    if (initial_state.is_logged_in) {
      if (!Access.isAllowed(initial_state.role)) {
        initial_state.is_logged_in = false;
        initial_state.user = null;
        initial_state.role = null;
        initial_state.key = null;
        DB.set("state", initial_state);
      }
    }

    //set browser info in env key
    //App.set({ env: EnvDetector.env });
    self.setupSubscribers();

    //online/offline
    window.addEventListener("offline", function () {
      ONLINE.turnOff();
    });
    window.addEventListener("online", function () {
      ONLINE.turnOn();
    });

    //check for application updates every 5 minutes
    // if (K_ENV != K_ENV_DEV) {
    var appUpdateInterval = window.setInterval(function () {
      Net.ajax({
        url: "./version.json?t=" + new Date().getTime(),
        method: "GET",
      })
        .then(function (r) {
          var current = ("{{version}}" + "{{build}}").replace(/\s?\[dev\]/, ""),
            newv = (r.response.version + r.response.build).replace(
              /\s?\[dev\]/,
              ""
            );
          if (current !== newv) {
            UPDATE_READY.turnOn();
            //stop checking if we already know we have an update
            window.clearInterval(appUpdateInterval);
          }
        })
        .catch(function () {});
    }, 300000);
    // }

    if (initial_state.is_logged_in) {
      MAINGROUPS.set(Access.mainGroups());
      MAINSECTIONS.set(Access.mainSections(initial_state.role));
      setTimeout(function () {
        IS_LOADING.turnOff();
      }, 500);
    } else {
      setTimeout(function () {
        IS_LOADING.turnOff();
      }, 500);
    }
  }

  self.setupSubscribers = function () {
    IS_LOGGED_IN.set(initial_state.is_logged_in);
    USER.set(initial_state.user);
    KEY.set(initial_state.key);
    ROLE.set(initial_state.role);
    ICON_STYLE.set(initial_state.icon_style);
    COLLAPSED_SIDEBAR.set(initial_state.collapsed_sidebar);
    CART_COUNT.set(initial_state.cart_count);
    ORDER_LINES.set(initial_cart.order_lines);

    IS_LOGGED_IN.subscribe((v) => {
      initial_state = Object.assign(initial_state, { is_logged_in: v });
      DB.set("state", initial_state);
    });

    USER.subscribe((v) => {
      initial_state = Object.assign(initial_state, { user: v });
      DB.set("state", initial_state);
    });

    KEY.subscribe((v) => {
      initial_state = Object.assign(initial_state, { key: v });
      DB.set("state", initial_state);
    });

    ROLE.subscribe((v) => {
      initial_state = Object.assign(initial_state, { role: v });
      DB.set("state", initial_state);
    });

    ICON_STYLE.subscribe((v) => {
      initial_state = Object.assign(initial_state, { icon_style: v });
      DB.set("state", initial_state);
    });

    COLLAPSED_SIDEBAR.subscribe((v) => {
      initial_state = Object.assign(initial_state, { collapsed_sidebar: v });
      DB.set("state", initial_state);
    });

    CART_COUNT.subscribe((v) => {
      initial_state = Object.assign(initial_state, { cart_count: v });
      DB.set("state", initial_state);
    });

    ORDER_LINES.subscribe((v) => {
      initial_cart = Object.assign(initial_cart, { order_lines: v });
      DB.set("cart", initial_cart);
    });
  };

  self.authenticate = function (u, p) {
    return new Promise(function (fulfil, reject) {
      Net.authenticate(u, p).then(
        function (r) {
          if (Access.isAllowed(r.role)) {
            MAINGROUPS.set(Access.mainGroups());
            MAINSECTIONS.set(Access.mainSections(r.role));
            Net.setKey(r.key);
            fulfil(r);
          } else {
            reject({
              valid: false,
              response: {
                userMessage: "You do not have sufficient access permissions",
              },
            });
          }
        },
        function (r) {
          reject(r);
        }
      );
    });
  };

  self.deauthenticate = function () {
    //scrub key from Net
    Net.setKey(null);
  };
};

//this ensures a singleton of this class
export default new currentState();
