<div class="columns is-multiline dashboard-links">
  {#if $USER && !$USER.shared_account}
    <div class="column is-3">
      <a class="card is-block" href="#profile">
        <div class="card-content has-text-centered">
          {#if $USER && $USER.avatar}
            <figure class="image is-32x32" style="margin: auto;">
              <img alt="" src={$USER && $USER.avatar} />
            </figure>
          {/if}
          <span class="title is-4 is-block">Update Profile</span>
        </div>
      </a>
    </div>
  {/if}
  {#each sections as section}
    {#if section.visible !== false && section.name !== "Dashboard"}
      <div class="column is-3">
        <a class="card is-block" href="#{section.url}">
          <div class="card-content has-text-centered" style="padding-top:2rem">
            <Icon icon={section.icon} size="medium" icon_class="title is-4" />
            <span class="title is-4 is-block">{section.title}</span>
          </div>
        </a>
      </div>
    {/if}
  {/each}
</div>

<hr class="" style="background-color: rgb(215 215 215);height: 1px;" />
<DashboardCategories model={categoryModel} />

<script>
  import { Icon } from "@kws3/ui";
  import { USER } from "../../../services/globalStore";
  import categoryModel from "../../../models/categories";
  import DashboardCategories from "../helpers/DashboardCategories.svelte";
  export let sections;
</script>
