<div class="page-content">
  <ViewScaffold {is_loading} {is_error} {error_message}>
    {#if is_admin_users}
      <AdminDashboard />
    {:else if is_customer_managers}
      <DashboardManagers />
    {:else}
      <CustomerDashboard {sections} />
    {/if}
  </ViewScaffold>
</div>

<script>
  import ViewScaffold from "./../helpers/ViewScaffold.svelte";
  import { USER, MAINSECTIONS, MAINGROUPS } from "../../services/globalStore";
  import AdminDashboard from "./AdminDashboard.svelte";
  import CustomerDashboard from "./customers/Dashboard.svelte";
  import DashboardManagers from "./customers/DashboardManagers.svelte";

  let is_loading = false,
    is_error = false,
    error_message = "",
    sections;

  $: user_role = $USER && $USER.role;
  $: is_admin_users = user_role === "A" || user_role === "U";
  $: is_customer_managers = user_role === "CA" || user_role === "CM";
  $: {
    let ret = [];
    for (let k in $MAINSECTIONS) {
      // eslint-disable-next-line no-prototype-builtins
      if ($MAINSECTIONS.hasOwnProperty(k)) {
        let element = $MAINSECTIONS[k],
          item = {
            name: k,
            title: element.title ? element.title : k,
            url: element.url,
            icon: element.icon,
            visible: element.visible,
            children: [],
          };

        if (element.visible !== false) {
          // eslint-disable-next-line no-prototype-builtins
          if (element.hasOwnProperty("group")) {
            if (typeof ret[element.group] == "undefined") {
              ret[element.group] = {
                name: element.group,
                title: element.group,
                icon: element.icon,
                children: [],
              };
              let groupDetails = $MAINGROUPS[element.group];
              if (groupDetails) {
                ret[element.group].name = groupDetails.name || element.group;
                ret[element.group].title = groupDetails.title || element.group;
                ret[element.group].icon =
                  groupDetails.icon || ret[element.group].icon;
              }
            }
            ret[element.group].children.push(item);
          } else {
            if (typeof ret[k] == "undefined") {
              ret[k] = item;
            } else {
              let ch = ret[k].children;
              ret[k] = item;
              ret[k].children = ch;
            }
          }
        }
      }
    }

    sections = Object.values(ret);
  }
</script>
