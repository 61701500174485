import baseModel from "./basemodel.js";

export default new (class extends baseModel {
  constructor() {
    super();
    this.apiEndpoint = "/categories";
  }

  getDashboardCategories() {
    return this.getOne("dashboard");
  }
})();
