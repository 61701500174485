<li
  class="navigation-item {$NAV.activeTab === section.name ? 'is-active' : ''}">
  <div class="dropdown is-hoverable">
    <div class="dropdown-trigger">
      <a
        href={typeof section.url != "undefined"
          ? "#" + section.url
          : "javascript:void(0)"}
        data-cy={section.url}
        class="nav-section-header {has_children ? 'has-children' : ''}"
        on:click={() => (show_submenu = !show_submenu)}>
        <Icon class="section-icon" icon={section.icon} size="small" />
        <span class="link-name">{section.title}</span>
        {#if has_children}
          <Icon
            icon={!$COLLAPSED_SIDEBAR && !show_submenu
              ? "angle-right"
              : "angle-down"}
            class="dd-icon"
            inner_class="fa-2x"
            size="small" />
        {/if}
      </a>

      {#if has_children}
        <div class="dropdown-menu {show_submenu ? '' : 'collapsed'}">
          <div class="dropdown-content">
            {#each section.children as child}
              <a
                href="#{child.url}"
                class="dropdown-item {$NAV.activeView === child.name
                  ? 'is-active'
                  : ''}">
                <Icon icon={child.icon} size="small" />
                <span>{child.title}</span>
              </a>
            {/each}
          </div>
        </div>
      {/if}
    </div>
  </div>
</li>

<script>
  import { NAV, COLLAPSED_SIDEBAR } from "../../services/globalStore.js";
  import { Icon } from "@kws3/ui";

  export let section;

  let show_submenu = false;

  $: has_children = section.children && section.children.length;
</script>
