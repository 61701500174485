export function truncate(str, len) {
  return str && str.length >= len + 3 ? str.substr(0, len) + "..." : str;
}

export function nl2br(input) {
  if (!input) {
    return "";
  }
  return (input + "").replace(/\n/g, "<br>");
}

export function currency(n) {
  var nn = Number(n);
  return (isNaN(nn) ? 0 : nn).toFixed(2);
}

export function YNUtoString(val) {
  let message = {
    Y: "Yes",
    N: "No",
    U: "Unknown",
  };
  return message[val];
}

export function YNUtoClass(val) {
  let message = {
    Y: "is-success",
    N: "is-danger",
    U: "is-light",
  };
  return message[val];
}

export function DateToOrdinal(n) {
  return (
    n +
    (n > 0
      ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
      : "")
  );
}

export function randomIntegerFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomPercent(min = 1, max = 100) {
  return randomIntegerFromInterval(min, max) + "%";
}

export default {
  truncate,
  nl2br,
  currency,
  YNUtoString,
  YNUtoClass,
  DateToOrdinal,
  randomIntegerFromInterval,
  randomPercent,
};
