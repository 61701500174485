<Panel title={chartTitle} style="position:relative;" inner_class="py-0 px-1">
  <div class="tabs is-toggle is-small is-fullwidth" slot="center">
    {#if filter && filter.show}
      <ul>
        {#each Object.entries(filter.items) as item}
          <li class={filter.current === item[0] ? "is-active" : ""}>
            <a href={null} on:click={() => setCurrent(item)}>{item[1]}</a>
          </li>
        {/each}
      </ul>
    {/if}
  </div>

  <button
    type="button"
    slot="toolbar"
    class="button is-text is-pulled-right"
    on:click={load}>
    <Icon icon="refresh" size="small" />
  </button>

  {#if !is_error}
    <MixedChart
      {data}
      {labels}
      {sets}
      {width}
      {height}
      multiaxis={true}
      sparklines={false} />
  {/if}

  {#if is_loading}
    <Loader spinner_color="primary" has_overlay={true} />
  {/if}
</Panel>

<script>
  import { Icon, Panel, Loader, MixedChart } from "@kws3/ui";
  import Model from "../../../../models/chart";
  import { onMount } from "svelte";
  export let customer_id = false;
  let is_loading = false,
    is_error = false,
    error_message = "", // eslint-disable-line no-unused-vars
    width = "100%",
    height = "300",
    formatter = (value) => Number(value).toFixed(2),
    labels = [
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
    ],
    sets = [
      { name: "Placed", decimalsInFloat: 0 },
      { name: "Completed", decimalsInFloat: 0 },
      { name: "Revenue", type: "line", labels: { formatter }, opposite: true },
    ],
    data = [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ],
    filter = {
      show: true,
      items: {
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        yearly: "Yearly",
      },
      current: "monthly",
    },
    meta = {}, // eslint-disable-line no-unused-vars
    chartTitle = "Last 18 months",
    apiParms = "";

  $: {
    if (filter) {
      switch (filter.current) {
        case "monthly":
          chartTitle = "Last 18 months";
          break;
        case "weekly":
          chartTitle = "Last 12 weeks";
          break;
        case "yearly":
          chartTitle = "Last 6 years";
          break;
        case "daily":
          chartTitle = "Last 14 days";
          break;
      }
      apiParms = filter.current || "monthly";
    }
  }

  onMount(() => {
    load();
  });

  function load() {
    is_loading = true;
    let url = apiParms;
    if (customer_id) {
      url = apiParms + "/" + customer_id;
    }
    Model &&
      Model.getOne(url)
        .then((r) => {
          var _data = r.response;
          is_error = false;
          is_loading = false;
          labels = _data.labels;
          data[0] = Array(_data.labels.length).fill(0);
          data[1] = Array(_data.labels.length).fill(0);
          data[2] = Array(_data.labels.length).fill(0);

          data[0] = _data.placed.map((i) => Number(i));
          data[1] = _data.completed.map((i) => Number(i));
          data[2] = _data.revenue.map((i) => Number(i));
        })
        .catch((r) => {
          var user_message = "";
          if (
            typeof r != "undefined" &&
            typeof r.response != "undefined" &&
            typeof r.response.records != "undefined"
          ) {
            user_message = r.response.records.userMessage;
          }
          is_error = true;
          //is_loading = false;
          error_message = user_message;
        });
  }

  function setCurrent(item) {
    filter.current = item[0];
    apiParms = item[0];
    load();
  }
</script>
